import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useUIContext } from "../../context/Basicproviders";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  DownloadPdfbutton,
  UploadBtnStyle,
  DeleteButton,
} from "../../Style/Common.style";
import Searchbar from "../globel/Searchbar";
import { DownloadPdf, ShipmentObject } from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import Bottombar from "../globel/Bottombar";
import { useNavigate } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import Processloader from "../globel/Processloader";
import { MdAdd } from "react-icons/md";
import Shipmentprocess from "./Shipmentprocess";
import { useDispatch } from "react-redux";
import {
  FETCH_PROCESS_REQUEST,
  PROCESS_INTRANSACTION,
} from "../../redux/shipment/shipmentAction";
import { useShipmentMaster } from "../../redux/shipment/shipmentReducer";
import ManualShipment from "../modals/ManualShipment";
import {
  FCCODE_FILTER,
  SKU_FILTER,
  STATE_FILTER,
} from "../../redux/filter/filterAction";
import Swal from "sweetalert2";
import shipmenetServices from "../../services/shipmenet.services";

const Process = () => {
  const {
    setEditingRow,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    selectAll,
    setSelectAll,
    setCheckedRows,
    checkedRows,
    selectedDomain,
    processLoader,
    shipmentDic,
    setshipmentdic,
    searchValue,
    setItemsPerPage
  } = useUIContext();
  const dispatch = useDispatch();
  const [updatedShipment, setUpdatedShipment] = useState({});
  const { processData } = useShipmentMaster();
  const [openManualshipment, setManualshipment] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const fetchProcessdata = () => {
    dispatch({
      type: FETCH_PROCESS_REQUEST,
      payload: "in_process",
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
  };

  const handleCheckboxChange = (row) => {
    const isChecked = checkedRows.includes(row.id);
    const updatedCheckedRows = isChecked
      ? checkedRows.filter((checkedRow) => checkedRow !== row.id)
      : [...checkedRows, row.id];
      setEditingRow(null);
    setCheckedRows(updatedCheckedRows);
    setSelectAll(updatedCheckedRows.length === processData?.results?.length);
    isChecked
      ? setshipmentdic((prevdata) =>
          prevdata.filter((item) => item.id !== row.id)
        )
      : setshipmentdic((prevSelectedRows) => [
          ...prevSelectedRows,
          {
            ...row,
            shipped_quantity: row.shipped_quantity,
            shipment_status: "in_transaction",
          },
        ]);
  };

  const handleSelectAllChange = () => {
    const allChecked = !selectAll;
    setSelectAll(allChecked);
    const newCheckedRows = allChecked
      ? processData?.results.map((row) => row.id)
      : [];
    setCheckedRows(newCheckedRows);
    setshipmentdic(
      allChecked
        ? processData?.results.map((row) => ({
            ...row,
            shipped_quantity: row.shipped_quantity,
            shipment_status: "in_transaction",
          }))
        : []
    );
  };

  const handleInputChange = (row, fieldName, newValue) => {
    setUpdatedShipment((prevShipment) => ({
      ...prevShipment,
      [fieldName]: newValue,
    }));

    setshipmentdic((prevData) =>
      prevData.map((item) =>
        item.id === row.id
          ? {
              ...item,
              [fieldName]: newValue,
            }
          : item
      )
    );
  };

  const deleteProcess = async (row, deletetype) => {
    try {
      const payload = deletetype === "singledelete" ? row.id : checkedRows;
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Undo this item",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#ff0000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (result.isConfirmed) {
        shipmenetServices
          .undoprocessdata(payload, deletetype)
          .then(() => {
            showSuccessAlert("Process undo done successfully!");
            dispatch({
              type: FETCH_PROCESS_REQUEST,
              payload: "in_process",
              itemsPerPage: itemsPerPage,
              currentPage: currentPage,
            });
          })
          .catch((error) => console.log(error));
      }
    } catch (error) {
      console.error("Error during delete process:", error);
    }
  };

  const moveIntransction = () => {
    if (checkedRows.length === 0) {
      showErrorAlert("Please select rows data");
    } else {
      dispatch({
        type: PROCESS_INTRANSACTION,
        payload: shipmentDic,
        itemsPerPage: itemsPerPage,
        currentPage: currentPage,
        href: navigate,
      });
      setItemsPerPage(10);
      setCurrentPage(1)
    }
  };

  useEffect(() => {
    if (searchValue !== null && selectedDomain !== null) {
      if (searchValue) {
        dispatch({
          type: FETCH_PROCESS_REQUEST,
          payload: "in_process",
          itemsPerPage: itemsPerPage,
          currentPage: currentPage,
          sku: searchValue,
        });
      } else {
        fetchProcessdata();
        setCheckedRows([]);
        setEditingRow(null);
        setshipmentdic([]);
        setSelectAll(false);  
      }
    }
  }, [searchValue, selectedDomain, itemsPerPage, currentPage]);

  const openManualModal = () => {
    setManualshipment(true);
    dispatch({ type: SKU_FILTER });
    dispatch({ type: FCCODE_FILTER });
    dispatch({ type: STATE_FILTER });
  };
console.log("process data",processData);

  return (
    <>
      <Box
        sx={{
          height: "78vh",
          overflowY: "auto",
          padding: "0rem 2rem 0rem",
          width: "86vw",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Searchbar placeholder="Search sku" />
          <Box sx={{ display: "flex", gap: 1 }}>
            <DeleteButton onClick={() => deleteProcess("multidelete", "")}>
              <RiDeleteBin5Line size="1rem" /> Multi Undo
            </DeleteButton>
            <DownloadPdfbutton onClick={openManualModal}>
              <MdAdd size="1rem" />
              Add MANUAL
            </DownloadPdfbutton>
            <ShipmentObject label="Shipment object" prams="process_file" />
            <DownloadPdf data={processData} filename="process" />
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "2rem",
            height: "65vh",
          }}
        >
          <Shipmentprocess
            tabledata={processData}
            handleCheckboxChange={handleCheckboxChange}
            handleSelectAllChange={handleSelectAllChange}
            undoProcess={deleteProcess}
            handleInputChange={handleInputChange}
            updatedShipment={updatedShipment}
            setUpdatedShipment={setUpdatedShipment}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {processLoader ? (
            <Processloader />
          ) : (
            <UploadBtnStyle
              sx={{ padding: "0.5rem 3rem" }}
              onClick={moveIntransction}
            >
              In-Transaction
            </UploadBtnStyle>
          )}
        </Box>
      </Box>
      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(processData?.count / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ProfileResetPassword />
      <ToastContainer />
      <ManualShipment
        openManualshipment={openManualshipment}
        handleclose={() => setManualshipment(false)}
      />
    </>
  );
};

export default Process;
