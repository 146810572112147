import {
  Modal,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  DownloadPdfbutton,
  Nullvalue,
  StyledTableCell,
  TableContainerStyled,
  UploadBtnStyle,
  VisuallyHiddenInput,
} from "../../Style/Common.style";
import { HiOutlineDownload } from "react-icons/hi";
import PageChange from "../globel/Pagination";
import { useUIContext } from "../../context/Basicproviders";
import { RiAddCircleFill } from "react-icons/ri";
import Warehouse from "./Warehouse";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import missingproductServices from "../../services/missingproduct.services";
import * as XLSX from "xlsx";
import { FiUploadCloud } from "react-icons/fi";
import { SlArrowDown } from "react-icons/sl";
import Product from "./Product";
import DatePicker from "react-datepicker";
import shipmenetServices from "../../services/shipmenet.services";

const WrongShipmentdata = ({
  wrongshipmentModal,
  setwrongshipmentModal,
  wrongShipment,
  setWrongshipment,
  shipmentModalname,
  setSelectedDate,
  selectedDate,
  selectDate,
  setSelectDate,
  errorDate,
  setErrorDate,
  handleClosemodal
}) => {
  const { itemsPerPage, currentPage, setCurrentPage } = useUIContext();
  const [openChildModal, setopenChildModal] = useState(false);
  const [openSkuModal, setOpenSkumodal] = useState(false);
  const [showDetailState, setShowDetailState] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [missingStateData, setMissingStateData] = useState({currentState: "", title: ""});

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setSelectDate({
      ...selectDate,
      start_date: value,
    });
  };

  const handleEndDateChange = (event) => {
    const { value } = event.target;
    setSelectDate({
      ...selectDate,
      end_date: value,
    });
  };

  const handleErrorStartDateChange = (event) => {
    const { value } = event.target;
    setErrorDate({
      ...errorDate,
      start_date: value,
    });
  };

  const handleErrorEndDateChange = (event) => {
    const { value } = event.target;
    setErrorDate({
      ...errorDate,
      end_date: value,
    });
  };

  // const deleteErrors = async (errorId) => {    
  //   try {
  //     const response = await shipmenetServices.deleteErrors(errorId);
  //     if (response?.result === "success") {
  //       showSuccessAlert("Error resolved successfully!");
  //     } else {
  //       showErrorAlert("Failed to resolve the error.");
  //     }
  //   } catch (error) {
  //     showErrorAlert(error?.response?.data?.message || "An error occurred while resolving.");
  //   }
  // };

  const deleteErrors = async (errorId) => {    
    try {
      // Perform the delete operation
      const response = await shipmenetServices.deleteErrors(errorId);
      if (response?.result === "success") {
        showSuccessAlert("Error resolved successfully!");
  
        const responseData = await shipmenetServices.getStateErrors(
          errorDate?.start_date,
          errorDate?.end_date
        );
  
        if (responseData?.result === "success") {
          setWrongshipment(responseData?.data);
        } else {
          showErrorAlert("Failed to fetch updated error data.");
        }
      } else {
        showErrorAlert("Failed to resolve the error.");
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message);
    }
  };
  
  

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const downloadExcel = async () => {
    try {
      let responseData;
      switch (shipmentModalname) {
        case "Wrong Shipments":
          responseData =
            await missingproductServices.downloadwrongShipmentdata();
          break;
        case "Missing FC":
          responseData = await missingproductServices.downloadFcProduct();
          break;
        case "Missing SKU":
          responseData = await missingproductServices.downloadSku();
          break;
        case "Missing State":
          responseData = await missingproductServices.downloadState();
          break;
        case "Disposition Items":
          responseData = await missingproductServices.downloadDisposition();
          break;
        case "Monthly Sales":
          responseData = await missingproductServices.downloadMonthlysales(
            selectDate?.start_date,
            selectDate?.end_date
          );
          break;
        case "Storage Fee":
          responseData = await missingproductServices.downloadStoragefee(
            selectedDate
          );
          break;
        case "Over Charged":
          responseData = await missingproductServices.downloadOvercharge();
          break;
        case "Shipment Errors":
          responseData = await shipmenetServices.downlaodErrors(
            errorDate?.start_date,
            errorDate?.end_date
          );
          break;
        default:
          break;
      }
      if (responseData?.result === "success") {
        if (
          shipmentModalname === "Over Charged" ||
          shipmentModalname === "Storage Fee" ||
          shipmentModalname === "Monthly Sales" ||
          shipmentModalname === "Shipment Errors"
        ) {
          const link = document.createElement("a");
          link.href = responseData?.data?.excel_url;
          link.download = shipmentModalname + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          const worksheet = XLSX.utils.json_to_sheet(
            responseData?.data?.results
          );
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, shipmentModalname + ".xlsx");
        }
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      showErrorAlert("Please select a file.");
      return;
    }
    try {
      const response = await missingproductServices.uploadStorageFee(file);
      showSuccessAlert("Storage fee file uploaded successfully!");
    } catch (error) {
      showErrorAlert(error?.response?.data?.data?.message);
    }
  };
  const showParticualStatedata = async (state) => {
    if (showDetailState === state) {
      setShowDetailState(null);
      setStateData(null);
    } else {
      setShowDetailState(state);
      try {
        let responseData;
        switch (shipmentModalname) {
          case "Monthly Sales":
            responseData = await missingproductServices.getStateMonthlysales(
              state,
              selectDate?.start_date,
              selectDate?.end_date
            );
            break;
          case "Storage Fee":
            responseData = await missingproductServices.getStateStoragefees(
              state,
              selectedDate
            );
            break;
          case "Over Charged":
            responseData = await missingproductServices.overCharged(state);
            break;
          case "Shipment Errors":
            responseData = await shipmenetServices.getStateErrors(
              state,
              errorDate?.start_date,
              errorDate?.end_date
            );
            break;
          default:
            break;
        }
        if (responseData?.result === "success") {
          setStateData(responseData?.data);
        }
      } catch (error) {
        showErrorAlert(error);
      }
    }
  };
  // const handleClosemodal = () => {
  //   setSelectDate({});
  //   setErrorDate({});
  //   setwrongshipmentModal(false);
  // };
  return (
    <>
      <Modal
        open={wrongshipmentModal}
        onClose={handleClosemodal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "1300px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <Typography variant="h5">{shipmentModalname}</Typography>
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                {shipmentModalname === "Monthly Sales" ||
                shipmentModalname === "Shipment Errors" ? (
                  <Box sx={{ zIndex: "9999" }}>
                    <div className="date-range-picker">
                      <div className="date-picker-container">
                        <input
                          type="date"
                          className="date-picker"
                          name="start_date"
                          value={
                            shipmentModalname === "Monthly Sales"
                              ? selectDate?.start_date
                              : errorDate?.start_date
                          }
                          onChange={
                            shipmentModalname === "Monthly Sales"
                              ? handleStartDateChange
                              : handleErrorStartDateChange
                          }
                        />
                      </div>
                      <div className="date-picker-container">
                        <input
                          type="date"
                          className="date-picker"
                          name="end_date"
                          value={
                            shipmentModalname === "Monthly Sales"
                              ? selectDate?.end_date
                              : errorDate?.end_date
                          }
                          onChange={
                            shipmentModalname === "Monthly Sales"
                              ? handleEndDateChange
                              : handleErrorEndDateChange
                          }
                        />
                      </div>
                    </div>
                  </Box>
                ) : shipmentModalname === "Storage Fee" ? (
                  <Box sx={{ zIndex: 9999 }}>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                    />
                  </Box>
                ) : null}

                {shipmentModalname === "Storage Fee" && (
                  <UploadBtnStyle
                    component="label"
                    startIcon={<FiUploadCloud style={{ marginLeft: "5px" }} />}
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                  >
                    Storage Fee
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </UploadBtnStyle>
                )}
                <DownloadPdfbutton
                  startIcon={<HiOutlineDownload />}
                  onClick={() => downloadExcel()}
                >
                  Download Excel
                </DownloadPdfbutton>
                <IoMdClose
                  onClick={handleClosemodal}
                  style={{ cursor: "pointer", fontSize: "2rem" }}
                />
              </Box>
            </Box>
            <hr />
            <Box
              sx={{
                height: "78vh",
                overflowY: "auto",
                padding: "1rem 1rem 1rem",
              }}
            >
              <Box
                sx={{
                  borderRadius: "0.50rem",
                  padding: "1rem 0rem",
                  marginBottom: "2rem",
                  height: "65vh",
                }}
              >
                <TableContainerStyled>
                  <Table aria-label="table">
                    <TableHead>
                      {wrongShipment.display_names &&
                        Object.keys(wrongShipment.display_names).length > 0 && (
                          <TableRow>
                            {Object.entries(wrongShipment.display_names).map(
                              ([key, value]) => (
                                <StyledTableCell align="center" key={key}>
                                  {value}
                                </StyledTableCell>
                              )
                            )}
                            {shipmentModalname === "Missing FC" ||
                            shipmentModalname === "Missing SKU" ||
                            shipmentModalname === "Missing State" ||
                            shipmentModalname === "Shipment Errors" ? (
                              <StyledTableCell align="center">
                                Action
                              </StyledTableCell>
                            ) : null}
                            {shipmentModalname === "Monthly Sales" ||
                            shipmentModalname === "Storage Fee" ||
                            shipmentModalname === "Over Charged" ? (
                              <StyledTableCell align="center">
                                Details
                              </StyledTableCell>
                            ) : null}
                          </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                      {wrongShipment.results &&
                      wrongShipment.results.length > 0 ? (
                        wrongShipment.results.map((row, index) => (
                          <React.Fragment key={row.id}>
                            <TableRow>
                              {Object.keys(
                                wrongShipment.display_names || {}
                              ).map((key) => (
                                <StyledTableCell align="center" key={key}>
                                  {row[key] === null ? (
                                    <Nullvalue>-</Nullvalue>
                                  ) : (
                                    <div>{row[key]}</div>
                                  )}
                                </StyledTableCell>
                              ))}
                              {shipmentModalname === "Missing FC" ||
                              shipmentModalname === "Missing SKU" ||
                              shipmentModalname === "Missing State" ||
                              shipmentModalname === "Shipment Errors" ? (
                                <>
                                  {shipmentModalname === "Shipment Errors" ? (
                                    <StyledTableCell align="center">
                                      <Button
                                        sx={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                          background: "#FF4433",
                                          color: "white",
                                        }}
                                        onClick={() => deleteErrors(row.id)}
                                      >
                                        Resolved
                                      </Button>
                                    </StyledTableCell>
                                  ) : (
                                    <StyledTableCell align="center">
                                      <RiAddCircleFill
                                        fontSize="2rem"
                                        onClick={() => {
                                          if (
                                            shipmentModalname === "Missing SKU"
                                          ) {
                                            setOpenSkumodal(true);
                                            setMissingStateData({
                                              currentState: row.fba_sku,
                                              title: "missingSku",
                                            });
                                          } else if (
                                            shipmentModalname === "Missing FC"
                                          ) {
                                            setopenChildModal(true);
                                            setMissingStateData({
                                              currentState: row.fba_fc_code,
                                              title: "missingFc",
                                            });
                                          } else if (
                                            shipmentModalname ===
                                            "Missing State"
                                          ) {
                                            setopenChildModal(true);
                                            setMissingStateData({
                                              currentState: row.ship_to_state,
                                              title: "missingState",
                                            });
                                          }
                                        }}
                                      />
                                    </StyledTableCell>
                                  )}
                                </>
                              ) : null}
                              {(shipmentModalname === "Monthly Sales" ||
                                shipmentModalname === "Storage Fee" ||
                                shipmentModalname === "Over Charged") && (
                                <StyledTableCell
                                  sx={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                >
                                  <SlArrowDown
                                    onClick={() => {
                                      if (
                                        shipmentModalname === "Over Charged"
                                      ) {
                                        showParticualStatedata(row.sku);
                                      } else {
                                        showParticualStatedata(row.state);
                                      }
                                    }}
                                  />
                                </StyledTableCell>
                              )}
                            </TableRow>
                            {showDetailState === row.state ||
                            showDetailState === row.sku ? (
                              <TableRow>
                                <TableCell
                                  colSpan={
                                    Object.keys(
                                      wrongShipment.display_names || {}
                                    ).length + 2
                                  }
                                >
                                  <TableContainer
                                    sx={{ border: "2px solid #C0C0C0" }}
                                  >
                                    <Table>
                                      <TableHead>
                                        {stateData?.display_names &&
                                          Object.keys(stateData?.display_names)
                                            .length > 0 && (
                                            <TableRow>
                                              {Object.entries(
                                                stateData?.display_names
                                              ).map(([key, value]) => (
                                                <TableCell
                                                  sx={{
                                                    textAlign: "center",
                                                    fontSize: "1rem",
                                                    fontWeight: "bold",
                                                  }}
                                                  key={key}
                                                >
                                                  {value}
                                                </TableCell>
                                              ))}
                                            </TableRow>
                                          )}
                                      </TableHead>
                                      <TableBody>
                                        {stateData?.results &&
                                        stateData?.results.length > 0 ? (
                                          stateData?.results.map(
                                            (row, index) => (
                                              <TableRow
                                                key={row.id}
                                                sx={{
                                                  backgroundColor:
                                                    index % 2 === 0
                                                      ? "#ffffff"
                                                      : "#F9FAFC",
                                                }}
                                              >
                                                {Object.keys(
                                                  stateData?.display_names || {}
                                                ).map((key) => (
                                                  <TableCell
                                                    align="center"
                                                    key={key}
                                                  >
                                                    {row[key] === null ? (
                                                      <Nullvalue>-</Nullvalue>
                                                    ) : (
                                                      <div>{row[key]}</div>
                                                    )}
                                                  </TableCell>
                                                ))}
                                              </TableRow>
                                            )
                                          )
                                        ) : (
                                          <TableRow>
                                            <TableCell
                                              colSpan={
                                                Object.keys(
                                                  stateData?.display_names || {}
                                                ).length
                                              }
                                            >
                                              NO ROW DATA
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </TableCell>
                              </TableRow>
                            ) : null}
                          </React.Fragment>
                        ))
                      ) : (
                        <TableRow sx={{ textAlign: "center" }}>
                          <TableCell
                            colSpan={
                              Object.keys(wrongShipment.display_names || {})
                                .length + 1
                            }
                          >
                            NO ROW DATA
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainerStyled>
              </Box>
            </Box>
            {shipmentModalname === "Monthly Sales" ||
            shipmentModalname === "Storage Fee" ||
            shipmentModalname === "Over Charged" ? null : (
              <PageChange
                currentPage={currentPage}
                totalPages={Math.ceil(wrongShipment?.count / itemsPerPage)}
                itemsPerPage={itemsPerPage}
                onChangePage={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Modal>
      <Warehouse
        open={openChildModal}
        handleClose={() => setopenChildModal(false)}
        currentState={missingStateData}
      />
      <Product
        open={openSkuModal}
        handleClose={() => setOpenSkumodal(false)}
        currentState={missingStateData}
      />
    </>
  );
};

export default WrongShipmentdata;
